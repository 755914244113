import React, { useEffect } from 'react';
import './package-tags.less';
import { services } from '@comall-backend-builder/core';
import JsBarcode from 'jsbarcode';

export const PackageTags: React.FC<{
    data: AnyObject[];
}> = (props) => {
    const { data = [] } = props;

    const dryGoods = data.filter((item) => item.type === 1);
    const coldGoods = data.filter((item) => item.type === 2);

    /** 当前包裹在当前分类的索引 */
    const newData: any = data.map((item) => {
        const currentIndex =
            item.type === 2
                ? coldGoods.findIndex((coldItem) => coldItem === item) + 1
                : dryGoods.findIndex((dryItem) => dryItem === item) + 1;

        return {
            ...item,
            countInfo: currentIndex,
        };
    });

    useEffect(() => {
        newData.forEach((e: any) => {
            if (e.outerOrderNumber) {
                JsBarcode(`#package-${e.outerOrderNumber}`, e.outerOrderNumber.slice(-15), {
                    displayValue: false,
                });
            }
        });
    }, [newData]);

    return (
        <>
            <style>{`@page {margin: 0mm;}`}</style>
            {newData.map((item: any) => (
                <div className='package-tag'>
                    <img className='barcode' id={`package-${item.outerOrderNumber}`}></img>
                    <div className='package-tag-item-type'>
                        {item.type === 1
                            ? services.language.getText('components.PackageTags.dryOrder')
                            : services.language.getText('components.PackageTags.frozenOrder')}
                    </div>
                    <div className='package-tag-item'>
                        <div className='package-tag-item-title'>
                            {services.language.getText('components.PackageTags.orderNumber')}：
                        </div>

                        {item.outerOrderNumber}
                    </div>
                    <div className='package-tag-item'>
                        <div className='package-tag-item-title'>
                            {services.language.getText('components.PackageTags.customer')}：
                        </div>

                        {item.customer}
                    </div>
                    <div className='package-tag-item'>
                        <div className='package-tag-item-title'>
                            {services.language.getText('components.PackageTags.address')}：
                        </div>
                        {item.deliveryAddress}
                    </div>
                    <div className='package-tag-item'>
                        <span className='package-tag-item-title'>
                            {services.language.getText('components.PackageTags.deliveryDate')}：
                        </span>
                        <span className='package-tag-item-number'>
                            {item.expressDeliveryTime.split(' ')[0]}
                        </span>
                    </div>
                    <div className='package-tag-item-total-type'>
                        {/** 有干货且有冻货 */}
                        {item.haveColdGoods &&
                            item.normalNumber > 0 &&
                            services.language.getText('components.PackageTags.hasFrozenDry')}
                        {/**只有冻货 */}
                        {item.haveColdGoods &&
                            (item.normalNumber <= 0 || !item.normalNumber) &&
                            services.language.getText('components.PackageTags.onlyFrozen')}
                        {/**只有干货 */}
                        {!item.haveColdGoods &&
                            item.normalNumber > 0 &&
                            services.language.getText('components.PackageTags.onlyDry')}
                    </div>
                    <div className='package-tag-item'>
                        {item.type === 1
                            ? services.language.getText('components.PackageTags.dryUnits')
                            : services.language.getText('components.PackageTags.frozenUnits')}
                        ：
                        <span className='package-tag-item-number'>
                            <span> ___{item.countInfo}___</span>/
                            <span>
                                ___{item.type === 1 ? item.normalNumber : item.coldNumber}___
                            </span>
                        </span>
                    </div>
                </div>
            ))}
        </>
    );
};
