import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, getLanguageColumns, getLanguageProperties } from '../../mixin';
import { formatNameProperties } from '../util';
import { ONLINE_STORE_TABLE } from '@/configs/pro-table-constants';
import { services } from '@comall-backend-builder/core';
import { DELIVERY_FLAG_OPTIONS, RECOMMEND_PRODUCT_TYPE } from '@/constants';
import { createRecommendProductComponents } from '@/configs/product/recommend-product';
import { language } from '@comall-backend-builder/core/lib/services';
import { message } from 'antd';

export const config: Config = {
    entities: {
        merchantsStore: {
            apiPath: '/loader/dc-goods/admin/merchant-store',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<businessCooperator.merchantsId>>',
                },
                nameMap: {
                    type: 'object.subForm',
                    properties: formatNameProperties(
                        'businessCooperator.merchantsName',
                        'businessCooperator.placeHolder',
                        'businessCooperator.nameError',
                        100
                    ),
                },
                deliveryMap: {
                    type: 'object.subForm',
                    properties: getLanguageProperties(
                        'string.text.trim',
                        'businessCooperator.deliveryInformation',
                        {
                            controlConfig: {
                                placeholder: '<<businessCooperator.placeHolder>>',
                            },
                            rules: [
                                { required: false },
                                {
                                    max: 100,
                                    message: '<<businessCooperator.deliveryError>>',
                                },
                            ],
                        }
                    ),
                },
                orderEmail: {
                    type: 'string',
                    displayName: '<<businessCooperator.email>>',
                    controlConfig: {
                        placeholder: '<<businessCooperator.emailPlaceholder>>',
                    },
                    rules: [
                        {
                            min: 1,
                            max: 100,
                            message: '<<businessCooperator.emailMsgError>>',
                        },
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                const emails = value ? value.split(';') : [];
                                const checkRE = /^[^@]+@[^@]+\.[^@]+$/;
                                if (
                                    emails.length &&
                                    emails.some((email: string) => !checkRE.test(email))
                                ) {
                                    return new Error(
                                        services.language.getText('businessCooperator.errEmail')
                                    );
                                } else {
                                    callback();
                                }
                            },
                        },
                    ],
                },
                logo: {
                    type: 'array.urlImage',
                    displayName: '<<businessCooperator.logo>>',
                    controlConfig: {
                        fileName: 'fileName',
                        uploadType: 'single',
                        multiple: false,
                        mode: 'picture-card',
                        maxCount: 1,
                        maxSize: 5 * 1000,
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<businessCooperator.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
                banner: {
                    type: 'array.merchantBannerListEdit',
                    displayName: '<<businessCooperator.banner>>',
                },
                transportType: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<onlineStore.shippingMethods.title>>',
                    // 合作商户不需要“自提”运输方式
                    options: DELIVERY_FLAG_OPTIONS,
                    rules: [{ required: true }],
                },
                deliveryExplain: {
                    type: 'string',
                    displayName: '<<businessCooperator.deliveryExplain>>',
                    controlConfig: {
                        maxLength: 100,
                        placeholder: '<<common.placeInput>>',
                    },
                },
                expressExplain: {
                    type: 'string',
                    displayName: '<<businessCooperator.expressExplain>>',
                    controlConfig: {
                        maxLength: 100,
                        placeholder: '<<common.placeInput>>',
                    },
                },
                descriptionMap: {
                    type: 'object.subForm',
                    properties: getLanguageProperties(
                        'string.text.paragraph',
                        'businessCooperator.descriptionMap',
                        {
                            controlConfig: {
                                maxLength: 1000,
                                placeholder: '<<common.placeInput>>',
                            },
                        }
                    ),
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<onlineStore.keyWords>>',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<businessCooperator.keywordPlaceholder>>',
                    },
                },
            },
        },
    },
    components: {
        MerchantsStoreContainer: {
            component: 'Viewport',
        },
        MerchantsStoreManagement: {
            component: 'Card',
            entity: 'merchantsStore',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'MerchantsStoreFilter',
                    },
                    {
                        component: 'MerchantsStoreTable',
                    },
                ],
                privilege: {
                    path: 'store.merchant.merchantview',
                    level: 'full',
                },
            },
        },
        MerchantsStoreFilter: {
            component: 'FilterFormPlus',
            style: {
                float: 'right',
                marginBottom: 15,
            },
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [{ property: 'keyword' }],
        },
        MerchantsStoreTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: ONLINE_STORE_TABLE,
            columns: [...getLanguageColumns('nameMap'), { property: 'id' }],
            actionColumn: {
                title: '<<actions>>',
                width: 200,
                fixed: 'right',
                items: [
                    {
                        text: '<<businessCooperator.recommendProduct>>',
                        type: 'link',
                        path: `/businessCooperator/recommend-product/${RECOMMEND_PRODUCT_TYPE.MERCHANT}/{{row.id}}`,
                        privilege: {
                            path: 'store.merchant.view_recommend_sku_list',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        config: {
                            actionType: 'component',
                            component: 'Button',
                            renderConfig: (row: any) => {
                                return {
                                    children: language.getText('businessCooperator.updateCategory'),
                                    style: { marginRight: 10 },
                                    type: 'link',
                                    row,
                                    onClick: async () => {
                                        try {
                                            await services.api.put(
                                                {},
                                                {
                                                    apiPath: `/dc-goods/admin/merchant/syncDisplayCategoriesToCache?merchantId=${row.id}`,
                                                }
                                            );
                                            message.success(
                                                language.getText(
                                                    'businessCooperator.updateSuccessMsg'
                                                )
                                            );
                                        } catch (e) {
                                            message.error(
                                                language.getText(
                                                    'businessCooperator.updateErrorMsg'
                                                )
                                            );
                                        }
                                    },
                                };
                            },
                        },
                        privilege: {
                            path: 'store.merchant.merchant_category_refresh',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<view>>',
                        type: 'link',
                        path: '/businessCooperator/detail/{{row.id}}',
                        privilege: {
                            path: 'store.merchant.view_detail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<edit>>',
                        path: '/businessCooperator/edit/{{row.id}}',
                        privilege: {
                            path: 'store.merchant.merchantedit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ...createRecommendProductComponents('Merchants', 'store.merchant'),
    },
};
